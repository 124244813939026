<script>
/**
 
 */

import {
  analyticsMethods
} from "@/state/helpers";

export default {
  components: {
    
  },
  data() {
    return {
      comments: [],
      currentPage:1,
      rows:0,
      perPage:10
    };
  },
  methods: {
    ...analyticsMethods,

    onCommentsListPageClicked(){
      this.loadData();
    },
    loadData(){
      let loader = this.$loading.show({
        container: this.$refs.componentContainer, 
      });

      const current = this.currentPage == 0 ? this.currentPage : this.currentPage - 1;
      const params=`${this.query}&content=${this.content}&limit=${this.perPage}&skip=${current * this.perPage}`

      this.getNPSCommentsFeedbackReport(params).then((res)=>{
        if(this.currentPage == 1)
          this.rows = res.total[0].total;

        this.comments = res.comments;
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    }
  },

  mounted() {
    
  },
  props: {
    query : String,
    content: String
  },
  watch:{
    query: {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.loadData();
        }
      }
    }
  }
};
</script>

<template>
    <div class="card" ref="componentContainer">
      <div class="card-body">
        <h4 class="card-title">{{$t('feedback_analytics.comments')}}</h4>
        <div class="table-responsive">
          <table class="table b-table align-middle dt-responsive nowrap w-100 table-check">
            <thead class="">
              <tr class="">
                <th scope="col" class=""></th>
                <th scope="col" class="">{{$t('feedback_analytics.sentiment')}}</th>
                <th scope="col" class="">{{$t('feedback_analytics.rate')}}</th>
              </tr>
            </thead>
            <tbody class="">
              <tr v-for="(data, index) in comments" :key="index">
                <td scope="col" class="">{{ data.comments }}</td>
                <td scope="col" class=""><span class="badge" :class="{
														'bg-success': `${data.sentiment}` === 'positive',
														'bg-info': `${data.sentiment}` === 'neutral',
														'bg-danger': `${data.sentiment}` === 'negative',
														}"
														>{{$t(`feedback_analytics.sentiment_${data.sentiment}`)}}</span></td>
                <td scope="col" class="">{{ data.value }}</td>
              </tr>
            </tbody>
          </table>
          <div class="mb-3" v-if="rows > perPage">
					<div class="col">
						<div class="dataTables_paginate paging_simple_numbers float-end">
							<ul class="pagination pagination-rounded mb-0">
								<b-pagination
									@input="onCommentsListPageClicked"
									v-model="currentPage"
									:total-rows="rows"
									:per-page="perPage">
								</b-pagination>
							</ul>
						</div>
					</div>
				</div>
        </div>
      </div>
    </div>
</template>

<style scoped>
.interact{
    cursor: pointer;
}
</style>