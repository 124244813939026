<script>
import {
  analyticsMethods  
} from "@/state/helpers";

export default {
  data() {
    return {
      npsValue: '-',
      totalFeedbacks: '-',
      promoters: '-',
      detractors: '-',
      passives : '-',
      promotersPercentage: 0,
      detractorsPercentage: 0,
      passivePercentage: 0,
    };
  },
  components:{},
  props: {
    query : String,
    content: String
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    ...analyticsMethods,
    
    loadData(){
      let loader = this.$loading.show({
        container: this.$refs.componentContainer, 
      });

      const params=`${this.query}&content=${this.content}`

      this.getNPSFeedbackReport(params).then((res)=>{
         if(res && res.data?.total_feedbacks && parseInt(res.data.total_feedbacks) > 0){
          this.promoters = parseInt(res.data.promoters);
          this.passives = parseInt(res.data.passives);
          this.detractors = parseInt(res.data.detractors);

          this.promotersPercentage = (parseInt(res.data.promoters)/parseInt(res.data.total_feedbacks)) * 100;
          this.detractorsPercentage = (parseInt(res.data.detractors)/parseInt(res.data.total_feedbacks)) * 100;
          this.passivePercentage = (parseInt(res.data.passives)/parseInt(res.data.total_feedbacks)) * 100;

          this.totalFeedbacks = parseInt(res.data.total_feedbacks);

          this.npsValue = Math.round(parseFloat(res.data.nps));
         }
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    }
  },
  watch:{
    query: {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.loadData();
        }
      }
    }
  }
};
</script>
<template>
  <div class="card" ref="componentContainer">
    <div class="card-body">
      <h4 class="card-title mb-4">NPS Feedback</h4>
      <div class="text-center">
        <h1>{{ npsValue }}</h1>
        <p>{{ totalFeedbacks }} {{ $t("feedback_analytics.responses") }}</p>
      </div>
      <hr>
      <div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="py-2">
              <h5 class="font-size-14">
                <i class= "bx bx-happy-heart-eyes"></i> {{ $t("feedback_analytics.promoters") }} <span class="float-end">{{ promoters }}</span>
              </h5>
              <div class="progress animated-progess progress-sm">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  :style="{width: `${promotersPercentage}%`}"
                  :aria-valuenow="promotersPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="py-2">
              <h5 class="font-size-14">
                <i class= "bx bx-angry"></i> {{ $t("feedback_analytics.detractors") }} <span class="float-end">{{ detractors }}</span>
              </h5>
              <div class="progress animated-progess progress-sm">
                <div
                  class="progress-bar bg-danger"
                  role="progressbar"
                  :style="{width: `${detractorsPercentage}%`}"
                  :aria-valuenow="detractorsPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="py-2">
              <h5 class="font-size-14">
                <i class= "bx bx-meh-alt"></i>  {{ $t("feedback_analytics.passives") }} <span class="float-end">{{ passives }}</span>
              </h5>
              <div class="progress animated-progess progress-sm">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  :style="{width: `${passivePercentage}%`}"
                  :aria-valuenow="passivePercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>