<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
//import Multiselect from "vue-multiselect";
import NPSFeedback from "@/components/dashboard/personalizations/nps-feedback";
import IconsFeedback from "@/components/dashboard/personalizations/icons-feedback";
import NpsComments from "@/components/dashboard/personalizations/nps-comments";

import {
  analyticsMethods,
  contentMethods
} from "@/state/helpers";


/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Feedback Analytics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
    //Multiselect,
    NPSFeedback,
    NpsComments,
    IconsFeedback
  },
  data() {
    return {
      title: 'Feedback',
      items: [],
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      query: '',
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],

      isLocal: window.config.local ? true : false,
      personalizationId: null,
      contentName : ''
    };
  },
  mounted() {
    this.personalizationId = this.$route.query?.personalization;
    if(this.personalizationId){
      this.getContent(this.personalizationId).then((res)=>{
        this.contentName = res.data.name;
      })
    }
    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 
  },
  methods:{
    ...analyticsMethods,
    ...contentMethods,

    formatDate(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    },

    applyFilter(query){
      
      this.query = query;
    
    },
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-2">
            <h5 class="mb-0 card-title" style="font-size: 17px;">Feedback - {{contentName}}</h5>
            <p class="text-muted mb-0">{{ $t("feedback_analytics.subtitle") }}</p>
          </div>
          <div class="text-end w-50">
            <div class="row">
              <div class="col d-flex justify-content-end pe-0" >
              </div>
              <div class="col">
                <!--<p class="mb-0 text-start">{{$t('reco_analyze.date_range')}}</p>-->
                <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range :format="'DD.MM.YYYY'" :clearable="false" :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
                </date-picker>    
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <NPSFeedback :query="query" :content="personalizationId"/>
        <IconsFeedback :query="query" :content="personalizationId"/>
      </div>
      <div class="col-xl-8">
        <NpsComments :query="query" :content="personalizationId"/>
      </div>
    </div>
</Layout>
</template>

<style scoped>
 .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

</style>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}
</style>
