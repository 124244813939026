<script>
import {
  analyticsMethods  
} from "@/state/helpers";

export default {
  data() {
    return {
      totalFeedbacks: '-',
      love: '-',
      like: '-',
      neutral : '-',
      dislike: '-',
      hate: '-',
      avgValue: 0,
      data: {},
      images: {
        hate: 'https://i.imgur.com/13nDxgv.png',
        dislike: 'https://i.imgur.com/jXJFtmI.png',
        neutral: 'https://i.imgur.com/27xlZTk.png',
        like: 'https://i.imgur.com/BZQCeCY.png',
        love: 'https://i.imgur.com/kX19z0B.png',
      }
    };
  },
  components:{},
  props: {
    query : String,
    content: String
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    ...analyticsMethods,
    
    getAVG(){
      const categoryValues = {
        hate: 1,
        dislike: 2,
        neutral: 3,
        like: 4,
        love: 5,
        total_feedbacks: 0
      };

      let weightedSum = 0;
      
      for (const [key, value] of Object.entries(this.data)) {
        weightedSum += parseInt(value) * categoryValues[key];
      }
      
      const avgValue = weightedSum / this.totalFeedbacks;
      
      return Object.keys(categoryValues).reduce((prev, curr) => {
        return (Math.abs(categoryValues[curr] - avgValue) < Math.abs(categoryValues[prev] - avgValue) ? curr : prev);
      });
    },
    loadData(){
      let loader = this.$loading.show({
        container: this.$refs.componentContainer, 
      });

      const params=`${this.query}&content=${this.content}`

      this.getFeedbackTypeReport(params).then((res)=>{
        this.data = res.data;

        if(res && res.data?.total_feedbacks && parseInt(res.data.total_feedbacks) > 0){
          this.love = parseInt(res.data.love);
          this.like = parseInt(res.data.like);
          this.neutral = parseInt(res.data.neutral);
          this.dislike = parseInt(res.data.dislike);
          this.hate = parseInt(res.data.hate);
          this.totalFeedbacks = parseInt(res.data.total_feedbacks);
          this.avgValue = this.getAVG();
         }
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    }
  },
  watch:{
    query: {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.loadData();
        }
      }
    }
  }
};
</script>
<template>
  <div class="card" ref="componentContainer">
    <div class="card-body">
      <h4 class="card-title mb-4">Feedback</h4>
      <div class="text-center">
        <h3>AVG <img :src="images[avgValue]" width="40px"/></h3>
        <p>{{ totalFeedbacks }} {{ $t("feedback_analytics.responses") }}</p>
      </div>
      <hr>
      <div>
        <div class="row">
          <div class="col text-center">
            <img :src="images['hate']" width="30px"/>
            <div>
              {{ hate }}
            </div>
          </div>
          <div class="col text-center">
            <img :src="images['dislike']" width="30px"/>
            <div>
              {{ dislike }}
            </div>
          </div>
          <div class="col text-center">
            <img :src="images['neutral']" width="30px"/>
            <div>
              {{ neutral }}
            </div>
          </div>
          <div class="col text-center">
            <img :src="images['like']" width="30px"/>
            <div>
              {{ like }}
            </div>
          </div>
          <div class="col text-center">
            <img :src="images['love']" width="30px"/>
            <div>
              {{ love }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>